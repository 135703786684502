import { bootstrapEnvironmentVariables } from "src/environments/environment";

async function startMainAngular(){
  await bootstrapEnvironmentVariables();
  
  return import("./bootstrap");
}

startMainAngular().catch((err) => console.error(err));



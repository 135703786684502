export type ClientProperties = Partial<Readonly<{
  production: boolean;
  type: "local" | "development" | "qa" | "automation" | "stage" | "production";
  titleName: string;
  baseApi: string;
  base: string;
  canvas: {
    redirect_uri: string;
    client_id: string;
    canvas_url: string;
  };
  captcha_key: string;
  google_analytics: string;
  micro_frontend_entries: string[];
  features_status: {
    selfScheduler: boolean
  };
}>>;

export let environment: ClientProperties = {
  
};



export async function bootstrapEnvironmentVariables() {
  try {
    environment = await fetch(
      `${window.location.origin}/server/api/web/client-properties`
    ).then((res) => res.json());
    Object.freeze(environment);
    Object.preventExtensions(environment);
  } catch (err) {
    console.error("Failed to fetch environment variables", err);
  }
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
